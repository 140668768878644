// Quasar SCSS (& Sass) Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the Sass/SCSS variables found in Quasar's source Sass/SCSS files.

// Check documentation for full list of Quasar variables

// Your own variables (that are declared here) and Quasar's own
// ones will be available out of the box in your .vue/.scss/.sass files

// It's highly recommended to change the default colors
// to match your app's branding.
// Tip: Use the "Theme Builder" on Quasar's documentation website.

$primary   : #162E66;
$secondary : #fda51f;
$accent    : #9C27B0;

$positive  : #21BA45;
$negative  : #C10015;
$info      : #31CCEC;
$warning   : #F2C037;

//ColorPalette
$gray: #4a4a4a;
$gray-light: #939393;
$gray-lighter: #f8f8f8;
$gray-bg: #fcfcfc;
$gray-disable: #f2f2f2;
$border-gray: rgba(82, 82, 82, 0.23);
$black: #000000;
$green: #162E66;
$greenButton: rgba(22, 46, 102, 0.2);
$green-dark: #5f7e28;
$blue: #3d5793;
$white: #ffffff;
$orange: #fda51f;
$red: #e84c1b;
$white-btn: #121212;
$vermex-bg: #A7C6CC;
$general-bg: #FAFAFA;
$vermex-gray: #545964;
$vermex-dark: #121212;
$vermex-danger: #EF444433;
$vermex-gray-action: #162E6633;
$vermex-gray-btn: #162E6620;
$error: #EF4444;
$discount: #EF4444;
$vermex-bg-btn: #F6F7F9;
$season: #6D7E3E;

//Font
$principal: 'Montserrat', sans-serif;

@mixin font($font-size, $font-weight, $font-color) {
	font-size: $font-size;
	font-weight: $font-weight;
	line-height: initial;
	color: $font-color;
	font-family: $principal;
}
